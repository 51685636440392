/* eslint-disable import/prefer-default-export */
import './src/styles/global.css'

export const onRouteUpdate = ({ location }) => {
  const hash = document.querySelectorAll(`a[rel="${location.hash}"]`)[0]
  if (hash && window) {
    window.scrollTo({
      top: hash.offsetTop,
    })
  }
  return true
}
